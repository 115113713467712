import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";
import { mainStyleTheme } from './theme'
import { Header } from './components'
import { Registration, NotExist } from './pages'
import { default as Routing } from './Routing'
import './App.css';
import { Container, Grid } from '@material-ui/core'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { ThankYouPage, UnactiveForm, UnactiveTokenPhone} from './pages/AfterForm'
import { useLocation } from 'react-router-dom'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function RoutedApp() {
  const [light, setLight] = useState(true)

  let location = useQuery()

  useEffect(() => {
      location.get('theme') === 'dark' ? setLight(false) : setLight(true)
  }, [location])

  return (
    <ThemeProvider theme={light ? mainStyleTheme.theme : mainStyleTheme.darkTheme}>
        <CssBaseline />
        <Header />
        <Container style={{ marginTop: "30px" }} maxWidth="lg">
            <Grid container>
                <Grid item xs={12}>
                    <Switch>
                        <Route exact path="/:link/:hash" component={Routing} />
                        <Route exact path="/"><Registration /></Route>
                        <Route exact path="/dziekujemy-za-rejestracje" component={ThankYouPage} />
                        <Route exact path="/error" component={NotExist} />
                        <Route exact path="/przeterminowany-link-rejestracja" component={UnactiveForm} />
                        <Route exact path="/przeterminowany-link-telefon" component={UnactiveTokenPhone} />
                    </Switch>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>
  );
}

export default RoutedApp;

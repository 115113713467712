import { makeStyles } from '@material-ui/core/styles'

const mainStyleClasses = makeStyles((theme) => ({
    hidden: {
      display: 'none',
    },
    floatRight: {
        float: 'right',
    },
    floatLeft: {
        float: 'left',
    },
    buttonMargin: {
        margin: '20px auto !important',
    },
    error: {
        color: 'red',
        fontSize: '12px',
        '@media (max-width:600px)': {
            fontSize: '10px',
          },
    },
    logo: {
        margin: '15px',
        width: '180px',
        '@media (max-width:600px)': {
            margin: '10px',
            width: '120px',
          },
    }
  }));

  export default mainStyleClasses
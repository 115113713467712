import React from 'react';
import logo from '../../img/LogoEuros.svg'
import AppBar from '@material-ui/core/AppBar'
import { mainStyleClasses } from '../../theme'
import { useLocation } from 'react-router-dom'

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const Header = () => {
    const classes = mainStyleClasses()

    let location = useQuery()

    return (
        <React.Fragment>{location.get('nav')=== 'false'? null 
        :
        <AppBar position="static">
            <img className={classes.logo} src={logo} alt="Euros Energy"
                title="Euros Energy"
            />
        </AppBar>}
        </React.Fragment>
    )
}

export default Header;
import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Radio, Checkbox } from 'final-form-material-ui'
import {
    Button,
    RadioGroup,
    FormGroup,
    FormControl,
    FormControlLabel,
    Grid,
    Typography, Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import { Redirect } from 'react-router-dom'
import formatString from "format-string-by-pattern"

import { default as fetchCustomerRegistration } from '../../data/fetch/fetchCustomerRegistration'

const required = value => (value ? undefined : "Pole obowiązkowe")

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min}` : null

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const fotonEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = "@fotontechnik.pl"
    const onlyEmail = anyString.includes(emailFormat)

    if (onlyEmail) {
        return "Proszę zarejestrować urządzenie na dane klienta"
    } else {
        return undefined
    }
}

const paternFAI = /^fai$/i
const paternFAO = /^fao$/i
const paternEG = /^eg$/i
const paternAIO = /^aio$/i



const formatUpperCase = (anyString, modelType) => {
    const onlyUpperCase = anyString.toUpperCase()
    return formatString(modelType, onlyUpperCase)
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);


const Registration = () => {
    const classes = mainStyleClasses()

    const [registrationStatus, setRegistrationStatus] = useState(null)
    const [modelType, setModelType] = useState({model: "", mask: ""})
    const [account, setAccount] = useState(null)
    const [customerAdress, setCustomerAdress] = useState(false)
    const [pass, setPass] = useState(null)
    const [open, setOpen] = useState(false)

    const deviceNumber = name => (value) => {
        if (value !== undefined && value.length >= 3) {
            let testAIO = paternAIO.test(value.slice(0, 3))
            let testFAI = paternFAI.test(value.slice(0, 3))
            let testEG = paternEG.test(value.slice(0, 2))
            let testFAO = paternFAO.test(value.slice(0, 3))

            switch (name) {
                case "ATMO":
                    if (!testFAI) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }
                case "GEO":
                    if (!testEG) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }
                case "ALLINONE":
                    if (!testAIO) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }

                case "OUTSIDE":
                    if (!testFAO) {
                        return "Nieprawidłowy numer seryjny jednostki zewnetrznej"
                    } else {
                        return undefined
                    }
            
                default:
                    return "Nieprawidłowy numer seryjny urządzenia"
            }
            
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePass = e => {
        setPass(e.target.value)
    }

    const handleOnClickModel = (e, values) => {
        if (e.target.value === "GEO")
            setModelType({model: "GEO", mask: "XX-0000-00000000"})
        if (e.target.value === "ATMO")
            setModelType({model: "ATMO", mask: "XXX-0000-00000000"})
        if (e.target.value === "ALLINONE")
            setModelType({model: "ALLINONE", mask: "XXX-0000-00000000"})
    }

    const handleOnClickAccount = (e, values) => {
        setAccount(e.target.value)
    }

    const dataPack = (data) => {
        let pack = {}
        // console.log({data})
        if(data.conf === "new"){
            pack = {
                email: data.email,
                phone: data.phone,
                password: data.password,
                first_name: data.first_name,
                last_name: data.last_name,
                i_city: data.i_city,
                i_street: data.i_street,
                i_street_number: data.i_street_number,
                i_post_code: data.i_post_code,
                c_city: customerAdress? data.c_city : data.i_city,
                c_street:  customerAdress? data.c_street : data.i_street,
                c_street_number:  customerAdress? data.c_street_number : data.i_street_number,
                c_post_code:  customerAdress? data.c_post_code : data.i_post_code,
                key: data.key,
                sn: data.serialNumber
            }
        } else {
            pack = {
                email: data.email,
                phone: data.phone,
                password: data.pass,
                i_city: data.i_city,
                i_street: data.i_street,
                i_street_number: data.i_street_number,
                i_post_code: data.i_post_code,
                c_city: customerAdress? data.c_city : data.i_city,
                c_street:  customerAdress? data.c_street : data.i_street,
                c_street_number:  customerAdress? data.c_street_number : data.i_street_number,
                c_post_code:  customerAdress? data.c_post_code : data.i_post_code,
                key: data.key,
                sn: data.serialNumber
            }
        }

        if(modelType.model !== "GEO"){
            pack = { ...pack, device_outside: data.device_outside}
        }

        return pack
    }

    const onSubmit = async values => {
        
        const dataSend = dataPack(values)
        // console.log({dataSend})

        fetchCustomerRegistration(values.conf, dataSend).then(response => {
            switch (response) {
                case 200:
                    return setRegistrationStatus(200)
                case 202:
                    return setRegistrationStatus('Takie konto już istenieje. Proszę zaznaczyć opcję "Posiadam już konto" i wprowadzić ustalone wcześniej hasło.')
                case 203:
                    return setRegistrationStatus('Takie konto nie istnieje. Proszę sprawdzić czy wprowadzono poprawnie dane: e-mail, telefon, hasło.')
                case 413:
                    return setRegistrationStatus('Konto o podanym adresie e-mail już istnieje.')
                case 414:
                    return setRegistrationStatus('Konto o podanym numerze telefonu już istnieje.')
                case 417:
                    return setRegistrationStatus('Urządzenie zostało przypisane do innego klienta. Sprawdź numer seryjny i klucz i spróbuj ponownie.')
                case 416:
                    return setRegistrationStatus('Urządzenie nie istnieje. Sprawdź numer seryjny i klucz i spróbuj ponownie.')
                case 418:
                    return setRegistrationStatus('Takie konto nie istnieje. Proszę sprawdzić czy wprowadzono poprawnie dane: e-mail, telefon, hasło.')
                case 410:
                    return setRegistrationStatus('Błąd bazy danych')
                case 421:
                    return setRegistrationStatus('Konto zostało zarejestrowane jako konto instalatora. Prosimy o rejestrację urządzenia na koncie prywatnym.')
                default:
                    return setRegistrationStatus('Wystąpił błąd przesyłania danych')
            }
        })
        setOpen(true)
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1">Rejestracja urządzenia na koncie klienta</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="email"
                                        component={TextField}
                                        type="email"
                                        label="Adres e-mail"
                                        fullWidth
                                        validate={composeValidators(required, formatEmail, fotonEmail)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="phone"
                                        component={TextField}
                                        type="phone"
                                        label="Numer telefonu"
                                        fullWidth
                                        validate={composeValidators(required, minLength(11))}
                                        parse={e => formatOnlyNumbersTel(e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                label="Posiadam już konto (posiadam inne zarejestrowane urządzenia)"
                                                control={
                                                    <Field
                                                        name="conf"
                                                        component={Radio}
                                                        type="radio"
                                                        value="exist"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickAccount(e, values); }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="Zakładam nowe konto"
                                                control={
                                                    <Field
                                                        name="conf"
                                                        component={Radio}
                                                        type="radio"
                                                        value="new"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickAccount(e, values); }}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {account === "exist" ?
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="pass"
                                            component={TextField}
                                            type="password"
                                            label="Hasło do aplikacji"
                                            fullWidth
                                            validate={composeValidators(required)}
                                        />

                                    </Grid> : null}
                                {account === "new" ? <React.Fragment><Grid item xs={12} sm={6}>
                                    <Field
                                        name="first_name"
                                        component={TextField}
                                        type="first_name"
                                        label="Imię"
                                        fullWidth
                                        validate={composeValidators(required, textOnly)}
                                    />
                                </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="last_name"
                                            component={TextField}
                                            type="last_name"
                                            label="Nazwisko"
                                            fullWidth
                                            validate={composeValidators(required, textOnly)}
                                        />
                                    </Grid>
                                </React.Fragment> : null}

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                label="ATMO (powietrzna)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="ATMO"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="ALLINONE (powietrzna)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="ALLINONE"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="GEO (gruntowa)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="GEO"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                        </RadioGroup>

                                    </FormControl><br />

                                </Grid>
                                {modelType.model && modelType.model === "GEO"? (<React.Fragment><Grid item xs={12} sm={6}>
                                    <Field
                                        name="serialNumber"
                                        component={TextField}
                                        type="text"
                                        label="Numer seryjny urządzenia"
                                        fullWidth
                                        validate={composeValidators(required, deviceNumber(modelType.model), minLength(12))}
                                        parse={e => formatUpperCase(e, modelType.mask)}
                                    />
                                </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="key"
                                            component={TextField}
                                            type="text"
                                            label="Klucz urządzenia"
                                            fullWidth
                                            validate={composeValidators(required, minLength(20), maxLength(20))}
                                        />
                                    </Grid></React.Fragment>) 
                                    : modelType.model && modelType.model !== "GEO"?
                                    <React.Fragment><Grid item xs={12} sm={6}>
                                    <Field
                                        name="serialNumber"
                                        component={TextField}
                                        type="text"
                                        label="Numer seryjny urządzenia"
                                        fullWidth
                                        validate={composeValidators(required, deviceNumber(modelType.model), minLength(12))}
                                        parse={e => formatUpperCase(e, modelType.mask)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="device_outside"
                                        component={TextField}
                                        type="text"
                                        label="Numer seryjny jednostki zewnętrznej"
                                        fullWidth
                                        validate={composeValidators(required, deviceNumber("OUTSIDE"), minLength(12))}
                                        parse={e => formatUpperCase(e, modelType.mask)}
                                    />
                                </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="key"
                                            component={TextField}
                                            type="text"
                                            label="Klucz urządzenia"
                                            fullWidth
                                            validate={composeValidators(required, minLength(20), maxLength(20))}
                                        />
                                    </Grid></React.Fragment> : null}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h3'>Miejsce montażu: </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8} sm={9}>
                                    <Field
                                        name="i_street"
                                        component={TextField}
                                        type="text"
                                        label="Ulica"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <Field
                                        name="i_street_number"
                                        component={TextField}
                                        type="text"
                                        label="Nr domu"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="i_city"
                                        component={TextField}
                                        type="text"
                                        label="Miejscowość"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="i_post_code"
                                        component={TextField}
                                        type="text"
                                        label="Kod pocztowy"
                                        fullWidth
                                        validate={composeValidators(required, minLength(6))}
                                        parse={e => formatOnlyNumbers(e)}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset">
                                            <FormControlLabel
                                                label="Adres do korespondencji (jeśli inny niż adres montażu)"
                                                control={
                                                    <Field
                                                        name="c_adress"
                                                        component={Checkbox}
                                                        type="checkbox"
                                                        value={false}
                                                        color="primary"
                                                        onClick={() => setCustomerAdress(!customerAdress)}
                                                    />
                                                }
                                            />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {customerAdress ? <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h3'>Adres do korespondencji: </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={9}>
                                        <Field
                                            name="c_street"
                                            component={TextField}
                                            type="text"
                                            label="Ulica"
                                            fullWidth
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={3}>
                                        <Field
                                            name="c_street_number"
                                            component={TextField}
                                            type="text"
                                            label="Nr domu"
                                            fullWidth
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="c_city"
                                            component={TextField}
                                            type="text"
                                            label="Miejscowość"
                                            fullWidth
                                            validate={required}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="c_post_code"
                                            component={TextField}
                                            type="text"
                                            label="Kod pocztowy"
                                            fullWidth
                                            validate={composeValidators(required, minLength(6))}
                                            parse={e => formatOnlyNumbers(e)}
                                        />
                                    </Grid>

                                </Grid>
                            </React.Fragment> : null}
                            {account === "new" ?
                                <React.Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='h3'>Ustawienie hasła do aplikacji mobilnej:</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                name="password"
                                                component={TextField}
                                                type="password"
                                                label="Wpisz hasło"
                                                fullWidth
                                                validate={composeValidators(minLengthPass(5), required)}
                                                onKeyUp={e => handlePass(e)}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                name="passwordConfirm"
                                                component={TextField}
                                                type="password"
                                                label="Powtórz hasło"
                                                fullWidth
                                                validate={composeValidators(passConfirm(pass), required)}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment> : null}

                            {modelType.model ? <Grid item xs={12} className="buttons" style={{ height: "60px" }}>

                                <Button className={`${classes.floatRight} ${classes.buttonMargin}`}
                                    variant="contained" color="primary" type="submit"
                                    disabled={submitting}> Wyślij </Button>

                            </Grid> : null}
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ marginTop: "20px" }}>* Wszystkie pola są obowiązkowe</Typography>
                                    <Typography variant="body2" className={classes.error}>{registrationStatus === 200 ? <Redirect to="/dziekujemy-za-rejestracje" /> : registrationStatus}</Typography>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="body2">{registrationStatus}</Typography>
                        </DialogContent>
                    </Dialog>
            </Grid>
        </React.Fragment>
    )
}

export default Registration
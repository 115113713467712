const fetchToken = async (hash) => {
    const response = fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/add_new/post/${hash}`, {
        method: "POST",
        headers: {
            "Origin": "http://localhost:3000"
        }
    }).then(response => response.status)

    return response
}

export default fetchToken
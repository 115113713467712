import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const ConfirmSendForm = () => {

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h2">Dziękujemy za rejestrację.</Typography>
                <Typography variant="body1">Twoje konto jest aktywne. Możesz sterować urządzeniami dodanymi do konta za pomocą aplikacji mobilnej.</Typography>
                {/*<br /><br />
                <Button variant="contained" color="primary" type="button" component={Link} to='/logowanie'> Przejdź do logowania </Button>*/}
            </Grid>
        </React.Fragment>
    )
}

export default ConfirmSendForm
import React from 'react'

import { Grid, Typography } from '@material-ui/core'

const ThankYouPage = () => {
    return (
        <Grid item xs={12}>
            <Typography variant="h2">Dziękujemy za rejestrację</Typography>
            <p>Na podany adres e-mail i numer telefony został wysłany link aktywacyjny. <br/>Link aktywny przez ograniczony czas: 72h.</p>
        </Grid>
    )
}

export default ThankYouPage
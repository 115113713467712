import React from 'react'
import './App.css';
import RoutedApp from './RoutedApp.js';
import { BrowserRouter as Router } from 'react-router-dom'

function App() {
  return (
    <Router className="App">
      <RoutedApp />
    </Router>
  );
}

export default App;

import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const UnactiveTokenPhone = () => {
    return(
        <Grid item xs={12}>
                <Typography variant="h2">Potwierdzenie zmiany numeru telefonu nie jest możliwe.</Typography>
                <p>Upłynął termin potwierdzenia. Zmień ponownie numer w aplikacji i potwierdź zmianę klikając w link aktywacyjny, który otrzymasz smsem.</p>
        </Grid>
    )
}

export default UnactiveTokenPhone
import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const UnactiveForm = () => {
    return(
        <Grid item xs={12}>
                <Typography variant="h2">Potwierdzenie rejestracji nie jest możliwe.</Typography>
                <p>Upłynął termin potwierdzenia. Zarejestruj się ponownie i potwierdź rejestrację klikając w link aktywacyjny, który otrzymasz zarówno e-mailem i smsem.</p>
        </Grid>
    )
}

export default UnactiveForm
const fetchCustomerRegistration = async (isNew, data) => {
    console.log(isNew)
    console.log(data)
    let response
    if (isNew === "new") {
        response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/check`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: data.email,
                phone: data.phone
            })
        }).then(async response => {
            if (response.status !== 200) {
                const newCustomer = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/add_new/pre`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(newcustomer => newcustomer.status)

                return newCustomer
            } else {
                return 202
            }
        })

    } else if (isNew === "exist") {
        response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/password_check`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: data.email,
                phone: data.phone,
                password: data.password
            })
        }).then(async response => {
            if (response.status === 200) {
                const newDevice = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/customers/add_new_device`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(newDevice => newDevice.status)

                return newDevice
            } else {
                return 203
            }
        })
    }

    return response
}

export default fetchCustomerRegistration
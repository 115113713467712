import React, { useState, useEffect } from 'react'
import fetchToken from '../data/fetch/fetchToken'
import fetchTokenPhone from '../data/fetch/fetchTokenPhone'
import { ConfirmSendForm } from '../pages/AfterForm'
import { PhoneConfirm } from '../pages'
import RedirectTo from './RedirectTo'

const Routing = ({ match }) => {

  const [status, setStatus] = useState(null)
  const [content, setContent] = useState(null)

  useEffect(() => {
    if (match.params.link === "ac") {
      fetchToken(match.params.hash)
        .then(res => {
          if (res !== null && res !== undefined) {
            switch (res) {
              case 200:
                return setStatus(match.params.hash);
              case 412:
                return setStatus('/przeterminowany-link-rejestracja');
              case 411:
                return setStatus('/error');
              default:
                return '/error';
            }
          } else {
            return setStatus('/error');
          }
        })
    } else {
      fetchTokenPhone(match.params.hash)
        .then(res => {
          if (res !== null && res !== undefined) {
            console.log({res})
            switch (res) {
              case 200:
                return setStatus(match.params.hash);
              case 412:
                return setStatus('/przeterminowany-link-telefon');
              case 411:
                return setStatus('/error');
              default:
                return '/error';
            }
          } else {
            return setStatus('/error');
          }
        })
    }
  }, [match.params.hash, match.params.method, match.params.link])


  return (
    <React.Fragment>
      {status === match.params.hash ?
          match.params.link === "ac" ?
            <ConfirmSendForm match={match.params.hash} /> 
            : match.params.link === "ph" ?
                <PhoneConfirm match={match.params.hash} /> 
                : <RedirectTo gotopage={'/error'} />
        : <RedirectTo gotopage={status} />}
        {console.log(status)}

    </React.Fragment>
  )
}

export default Routing
import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const PhoneConfirm = () => {
    return(
        <Grid item xs={12}>
                <Typography variant="h2">Potwierdzenie zmiany numeru telefonu.</Typography>
                <p>Twój numer telefonu został zmieniony.</p>
        </Grid>
    )
}

export default PhoneConfirm